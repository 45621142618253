import { header } from './modules/header'
import { popups } from './modules/popups'
import { sliders } from './modules/sliders'
import { stepCards } from './modules/step-cards'
import { links } from './modules/links'
import { projectsCarousels } from './modules/projects-carousels'

const afterLoad = () => {
    header.init()
    stepCards.init()
    projectsCarousels.init()
    sliders.init()
    links.init()
    popups.init()
}

document.addEventListener('DOMContentLoaded', afterLoad)