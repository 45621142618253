'use strict'

export const stepCards = (() => {
    const angleRatio = .2
    const stepCardsContainer = document.querySelectorAll('.step__cards')

    const stepCards = (container => {
        const _cards = container.querySelectorAll('.step__cards__item')
        const _containerRect = container.getBoundingClientRect()
        let _maxTop = 0

        _cards.forEach(card => {
            const _cardRect = card.getBoundingClientRect()
            const _top = (_containerRect.right - _cardRect.right) * angleRatio

            if(_top > _maxTop) _maxTop = _top

            card.style.marginTop = _top + 'px'
        })

        // set bottom padding
        const _steps = container.closest('.steps')
        if (!_steps) return
        
        const _stepsPadding = _steps.querySelector('.steps__padding');
        if(!_stepsPadding) return

        _stepsPadding.style.paddingBottom = _maxTop + 'px'
    })

    const stepAllCards = (() => {
        stepCardsContainer.forEach(container => stepCards(container))
    })

    const initWindowResizeHandler = (() => {
        window.addEventListener('resize', () => {
            stepAllCards()
        })
    })

    const init = () => {
        if (!stepCardsContainer.length) return
        
        stepAllCards()
        initWindowResizeHandler()
    }

    return {
        init
    }
})()