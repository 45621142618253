'use strict'

export const projectsCarousels = (() => {
    let containers = []

    const adjust = container => {
        const _containerRect = container.getBoundingClientRect()

        container.style.width = 'calc(100vw - ' + _containerRect.left + 'px)'
    }

    const adjustAll = () => {
        containers.forEach(container => adjust(container))
    }

    const init = () => {
        containers = document.querySelectorAll('.projects__carousel')

        adjustAll()

        window.addEventListener('resize', () => {
            adjustAll()
        })
    }

    return {
        init
    }
})()