'use strict'

export const equalCards = (() => {
    const makeCardsEqual = container => {
        const _cards = container.querySelectorAll('.card')

        if(!_cards.length) return

        let _maxHeaderHeight = 0

        _cards.forEach(card => {
            const _header = card.querySelector('.card-header')
            const _headerHeight = _header.offsetHeight

            if (_headerHeight > _maxHeaderHeight) {
                _maxHeaderHeight = _headerHeight
            }
        })

        _cards.forEach(card => {
            const _header = card.querySelector('.card-header')

            _header.style.height = _maxHeaderHeight + 'px'
        })
    }

    return {
        makeCardsEqual
    }
})()