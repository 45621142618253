'use strict'

export const header = (() => {
    const headerElement = document.querySelector('body > header')

    const changeHeader = () => {
        if(window.scrollY > 0) {
            if(!headerElement.classList.contains('header-fixed')) {
                headerElement.classList.add('header-fixed')
            }
        } else {
            headerElement.classList.remove('header-fixed')
        }
    }

    const init = () => {
        if(!headerElement) return

        document.onscroll = () => {
            changeHeader()
        }

        changeHeader()
    }

    return {
        init
    }
})()