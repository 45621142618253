'use strict'

export const links = (() => {
    const linkClickHandle = link => {
        const _href = link.dataset.href

        if (!_href) return false

        location.href = _href
    }

    const init = () => {
        const _links = document.querySelectorAll('[data-href]')

        _links.forEach(link => {
            link.addEventListener('click', () => linkClickHandle(link))
        })
    }

    return {
        init
    }
})()