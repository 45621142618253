'use strict'

import Splide from '@splidejs/splide'
import { equalCards } from './equal-cards'

export const sliders = (() => {

    const carousels = []
    const bigCarousels = []
    let hwwCarousel = null
    let hwwThumbCarousel = null
    let hwwTitles = null

    const initSliders = () => {
        const _sliderContainers = document.querySelectorAll('.splide')

        _sliderContainers.forEach(sliderContainer => {
            const newSlider = new Splide(sliderContainer)

            carousels.push(newSlider)

            if (sliderContainer.classList.contains('big__carousel') || sliderContainer.classList.contains('block__carousel')) {
                bigCarousels.push(newSlider)
            }

            if (sliderContainer.classList.contains('hww__carousel')) {
                hwwCarousel = newSlider
            }

            if (sliderContainer.classList.contains('hww__thumb__carousel')) {
                hwwThumbCarousel = newSlider
            }
        })

        initBigCarousels()

        if (hwwCarousel) {
            initHWWCarousel()
        }

        // Sync and mount HWW carousels
        if (hwwThumbCarousel && hwwCarousel) {
            hwwCarousel.sync(hwwThumbCarousel)

            hwwCarousel.mount()
            hwwThumbCarousel.mount()

            carousels.pop(hwwCarousel)
            carousels.pop(hwwThumbCarousel)
        }

        // Do mount for all other carousels
        carousels.forEach(slider => {
            slider.on('mounted', () => {
                const _container = slider.root

                if (_container.dataset.equalCards != undefined) {
                    equalCards.makeCardsEqual(_container)
                }
            })

            slider.mount()
        })
    }

    const initBigCarousels = () => {
        let currentIndex = -1

        bigCarousels.forEach(slider => {
            // fired after the slider is mounted
            slider.on('mounted', () => {
                setBigCarouselTotal(slider)
                setBigCarouselText(slider, 0)
                managePanel(slider)
            })

            // fired before carousel moves
            slider.on('move', newIndex => {
                if (newIndex != currentIndex) {
                    currentIndex = newIndex
                    setBigCarouselText(slider, currentIndex)
                }
            })

            // fired after carousel moves
            slider.on('moved', newIndex => {
                setBigCarouselCurrent(slider, newIndex + 1)
            })
        })
    }

    const initHWWCarousel = () => {
        hwwCarousel.on('move', newIndex => {
            // set title list current item
            const _list = hwwCarousel.root.querySelector('.hww__carousel__panel__titles__list')
            const _items = _list.querySelectorAll('div')

            _items.forEach(item => {
                item.classList.remove('current')
            })

            _items[newIndex].classList.add('current')

            // set list shift
            const _height = _items[0].offsetHeight
            _list.style.top = -newIndex * _height + 'px'

            // set url hash
            location.hash = _items[newIndex].dataset.hash
        })

        const scrollHwwThumbCarousel = () => {
            // scroll to top the carousel container
            hwwThumbCarousel.root.scrollIntoView({behaviour: 'smooth'})
        }

        const gotoSlideByHash = () => {
            let _hash = location.hash

            if (!_hash) return
            
            _hash = _hash.replace('#', '')

            const _title = document.querySelector('.hww__carousel__panel__titles__list > div[data-hash="' + _hash + '"]')

            if (!_title) return
            
            const _index = _title.dataset.index

            if (!_index) return
            
            hwwCarousel.go(_index - 1)
            scrollHwwThumbCarousel()
        }

        hwwThumbCarousel.on('move', () => {
            scrollHwwThumbCarousel()
        })

        hwwThumbCarousel.on('mounted', () => gotoSlideByHash())

        // handle clicks on titles in panel
        hwwTitles = document.querySelectorAll('.hww__carousel__panel__titles__list > div')

        hwwTitles.forEach(title => {
            title.addEventListener('click', () => {
                const _index = title.dataset.index

                if (!_index) return
                
                hwwCarousel.go(_index - 1)
            })
        })

        // handle hash changing
        onhashchange = () => gotoSlideByHash()
    }

    const setBigCarouselTotal = slider => {
        const _sliderContainer = slider.root
        const _container = _sliderContainer.querySelector('.big__carousel__panel__controls__inner__pages__total, .block__carousel__panel__controls__inner__pages__total')

        if(!_container) return

        _container.innerHTML = slider?.length.toString().padStart(2, '0')
    }

    const setBigCarouselCurrent = (slider, index) => {
        const _sliderContainer = slider.root
        const _container = _sliderContainer.querySelector('.big__carousel__panel__controls__inner__pages__current, .block__carousel__panel__controls__inner__pages__current')

        _container.innerHTML = index.toString().padStart(2, '0')
    }

    const setBigCarouselText = (slider, index) => {
        const _slide = slider.Components.Slides.getAt(index)

        if (!_slide) return false
        
        const _sliderContainer = slider.root
        const _textTarget = _sliderContainer.querySelector('.big__carousel__panel__text')
        const _textSource = _slide.slide.querySelector('.big__carousel__item__text')

        if(!_textTarget) return

        _textTarget.classList.add('inactive')

        setTimeout(() => {
            _textTarget.innerHTML = _textSource.innerHTML
            _textTarget.classList.remove('inactive')
        }, 300)
    }

    const managePanel = slider => {
        const _sliderContainer = slider.root

        if(!_sliderContainer) return false

        const _panel = _sliderContainer.querySelector('.big__carousel__panel')

        if(!_panel) return false

        const _panelBack = _panel.querySelector('.big__carousel__panel__back')

        if(!_panelBack) return false
        
        window.addEventListener('resize', () => {
            const _textTitle = _panel.querySelector('.big__carousel__panel__text .text__title')

            if(!_textTitle) return false

            const _rect = _textTitle.getBoundingClientRect()

            _panelBack.style.width = 'calc(' + (_rect.left + _rect.width).toFixed() + 'px + 10%)'
        })

        window.dispatchEvent(new Event('resize'))
    }

    const init = () => {
        initSliders()
    }

    return {
        init
    }
})()